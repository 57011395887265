import React from 'react'

const NotFound = () => {
    return (
        <center><img
            src="https://http.cat/404.jpg"
            alt="404-not found"
        ></img></center>
    )
}

export default NotFound